/* eslint-disable */
// prettier-ignore
export default {
  Resultados: {
    key: 'title',
    data: [
      { title: 'Mi Perfil', route: { name: 'perfil' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Código PIN', route: { name: 'perfil', hash: '#pin' }, icon: 'LockIcon', isBookmarked: false },
      { title: 'Información personal', route: { name: 'perfil', hash: '#general' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Actualización de contraseña', route: { name: 'perfil', hash: '#password' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Verificación de cuenta', route: { name: 'perfil', hash: '#verificacion' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'Notificaciones por email y Whatsapp', route: { name: 'perfil', hash: '#notificaciones' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Configuración del bot EQCoop', route: { name: 'perfil', hash: '#bot' }, icon: 'CpuIcon', isBookmarked: false },
      { title: 'Cuenta bancaria', route: { name: 'perfil', hash: '#banco' }, icon: 'LayersIcon', isBookmarked: false },
      { title: 'Mis tarjetas de pago', route: { name: 'perfil', hash: '#tarjetas' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Biografía y redes sociales', route: { name: 'perfil', hash: '#biografia' }, icon: 'InstagramIcon', isBookmarked: false },
      { title: 'Mi alias', route: { name: 'perfil', hash: '#alias' }, icon: 'AtSignIcon', isBookmarked: false },
      { title: 'Mis inicios de sesión', route: { name: 'perfil', hash: '#sesiones' }, icon: 'SmartphoneIcon', isBookmarked: false },
      { title: 'Eliminar mi cuenta', route: { name: 'perfil', hash: '#eliminar' }, icon: 'Trash2Icon', isBookmarked: false },
      { title: 'Inicio', route: { name: 'dashboard-analytics' }, icon: 'HomeIcon', isBookmarked: false },
      { title: 'Mis Billeteras de criptomonedas', route: { name: 'wallets' }, icon: 'BookIcon', isBookmarked: false },
      { title: 'NFTS', route: { name: 'nfts' }, icon: 'BookIcon', isBookmarked: false },
      { title: 'Listado de transacciones de criptomonedas', route: { name: 'wallets' }, icon: 'BookIcon', isBookmarked: false },
      { title: 'Transferencias interbilleteras', route: { name: 'transferencias' }, icon: 'SendIcon', isBookmarked: false },
      { title: 'Retiro de dinero', route: { name: 'retiros' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Transacciones', route: { name: 'transacciones' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Listado de retiros de dinero', route: { name: 'retiros' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Recargas de billetera', route: { name: 'recargar' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Listado de recargas realizadas', route: { name: 'recargar' }, icon: 'DollarSignIcon', isBookmarked: false },
     // { title: 'Remesas', route: { name: 'remesas' }, icon: 'SendIcon', isBookmarked: false },
      { title: 'Listado de remesas', route: { name: 'remesas' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Listado de contactos de remesas', route: { name: 'remesas' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Otros menús', route: { name: 'otros' }, icon: 'ServerIcon', isBookmarked: false },
      { title: 'Mis notificaciones', route: { name: 'notificaciones' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Solicitudes de empleados', route: { name: 'solicitudes' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'Mis referidos', route: { name: 'referidos' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'Mi Negocio', route: { name: 'negocio' }, icon: 'UsersIcon', isBookmarked: false },
     // { title: 'Videos tutoriales', route: { name: 'tutoriales' }, icon: 'YoutubeIcon', isBookmarked: false },
      { title: 'Rangos de promotores', route: { name: 'rangos' }, icon: 'UsersIcon', isBookmarked: false },
      { title: 'Marketplace', route: { name: 'marketplace' }, icon: 'StarIcon', isBookmarked: false },
    ],
  },
}
/* eslint-enable */
